<template>
  <van-cell class="flex align-center truck-item" @click="handleTruckClick">
    <div slot="icon" class="icon-wrap">
      <div class="flex align-center justify-center ">
        <Icon name="QT" class="font-20"> </Icon>
      </div>
    </div>
    <div class="flex flex-row justify-space-between align-center">
      <div class="text_main font-16">{{ truck.carLicensePlate }}</div>
      <div>
        <div class="text_muted font-12 text-right">
          <Icon :name="iconName" class="font-16 mr-4"> </Icon>
          <span>{{ truck.auditStatusName === '待审核' ? '已提交' : truck.auditStatusName }}</span>
        </div>
        <div class="text_muted font-12">
          <span class="pr-8'">{{ plateEnumMap[truck.plateColor] }}</span>
          <span>{{ truck.truckTypeName }}</span>
        </div>
      </div>
    </div>
  </van-cell>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api';
import { Cell } from 'vant';
import { TruckAuthStatus } from '@/constants';
import { useRouter } from '@/utils/compsitionHack';
import { plateEnumMap } from '@/views/mine/constants';

export default defineComponent({
  name: 'truck-item',
  components: {
    [Cell.name]: Cell
  },
  props: {
    truck: {
      type: Object
    }
  },
  setup(props) {
    const router = useRouter();

    const iconName = computed(() => {
      const iconMap = {
        [TruckAuthStatus.Waiting]: 'waiting',
        [TruckAuthStatus.Finish]: 'rz',
        [TruckAuthStatus.Fail]: 'tuikuanshibai'
      };
      return iconMap[props.truck.auditStatus];
    });

    const handleTruckClick = () => {
      const truckId = props.truck.truckId;
      // router.push({ path: PageEnum.CARRIER_FLEET_TRUCK, query: { id: truckId } });
      router.push({ path: '/mine/car/detail', query: { id: truckId } });
    };

    return {
      iconName,
      plateEnumMap,
      handleTruckClick
    };
  }
});
</script>

<style lang="less" scoped>
.truck-item {
  padding-bottom: 0;
  .icon-wrap {
    margin-right: 8px;
    margin-top: -0.16rem;
    & > div {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: rgba(135, 135, 135, 0.1);
    }
  }
  &::after {
    border-bottom: none;
  }
}
/deep/ .van-cell__value {
  padding-bottom: 0.16rem;
  &::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 0;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid #eeeeee;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}
</style>
