import { render, staticRenderFns } from "./DriverItem.vue?vue&type=template&id=20f82ddb&scoped=true&"
import script from "./DriverItem.vue?vue&type=script&lang=js&"
export * from "./DriverItem.vue?vue&type=script&lang=js&"
import style0 from "./DriverItem.vue?vue&type=style&index=0&id=20f82ddb&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20f82ddb",
  null
  
)

export default component.exports