<template>
  <Page :loading="loading">
    <Navbar title="我的车队" @clickLeft="handleRouterBack" :customClickLeft="true">
      <template #right>
        <Icon class="font-24" name="erweima" @click.native="handleShowQRCode"></Icon>
      </template>
    </Navbar>
    <div class="fleet-name pa-16">
      <div class="fleet-name-item d-flex pa-16">
        <div class="icon-circle text-center lh-32">
          <Icon name="wodechedui" />
        </div>
        <div class="lh-32 ml-8">{{ carrierName.name }}</div>
        <div class="lh-32 text-right flex-1" @click="visible = true">
          <Icon name="bianji" />
          <span class="text_primary">编辑</span>
        </div>
      </div>
    </div>
    <div class="page-content">
      <Tabs line-width="50%" sticky v-model="currentTabsRef" @click="handleChangeTab" class="flex-1">
        <Tab title="司机" name="DRIVER">
          <div v-if="driverListRef.length">
            <DriverItem v-for="(item, index) in driverListRef" :key="index" :driver="item" />
          </div>
          <Empty v-else imgWidth="0.84rem" :image="noContract" description="当前无司机" />
        </Tab>
        <Tab title="车辆" name="TRUCK">
          <div v-if="truckListRef.length">
            <TruckItem v-for="(item, index) in truckListRef" :truck="item" :key="index" />
          </div>
          <Empty v-else imgWidth="0.84rem" :image="noContract" description="当前无车辆" />
        </Tab>
      </Tabs>
    </div>
    <div class="page-footer bg-white">
      <Button @click="handleAddClick" type="primary">{{
        currentTabsRef === 'DRIVER' ? '邀请司机' : '添加车辆'
      }}</Button>
    </div>
    <Popup v-model="visible" :close-on-click-overlay="!!carrierRef">
      <div class="carrier-form py-40 px-20">
        <div class="font-16 fw-400">请输入车队名称</div>
        <van-form class="input-border">
          <van-field
            required
            v-model="carrierRef"
            input-align="left"
            maxlength="10"
            placeholder="请输入车队名称"
            :rules="FormObj.rules"
          />
        </van-form>
        <div class="buttons d-flex font-16 text-center lh-48 justify-space-between">
          <div class="button-cancel text_muted fw-500" @click="visible = false">取消</div>
          <div class="button-ok fw-500" @click="handleEditCarrierName">修改</div>
        </div>
      </div>
    </Popup>
  </Page>
</template>

<script>
import { computed, defineComponent, ref, onBeforeMount } from '@vue/composition-api';
import { apiModifyName } from '@/api/fleet';
import DriverItem from './components/DriverItem';
import TruckItem from './components/TruckItem';
import { Button, Tab, Tabs, Popup, Toast } from 'vant';
import { useCarrierStore } from '@/store/carrier';
import { useUserStore } from '@/store/user';
import { useRouter } from '@/utils/compsitionHack';
import { PageEnum } from '@/enums/pageEnum';
import ClientApp from '@/client/index';

import { useTruckVerifiedStore, emptyForm } from '@/store/truckVerified';
import AuthModeFactory from '@/services/AuthenticationMode/AuthModeFactory';
import Vehicle from '@/services/AuthenticationMode/Vehicle';
import { getPlatformAppAuditModeApi } from '@/api/common';
import ModeEnum from '@/services/AuthenticationMode/ModeEnum';

const authModeFactory = new AuthModeFactory(new Vehicle());

export default defineComponent({
  name: 'carrier-fleet',
  components: {
    DriverItem,
    TruckItem,
    Button,
    Popup,
    Toast,
    Tab,
    Tabs
  },
  setup() {
    const carrierStore = useCarrierStore();
    const visible = ref(false);
    const useUser = useUserStore();
    const loading = ref(true);
    const carrierName = computed(() => useUser.carrierInfo?.fleetInfoVO || {}); // 显示用
    const carrierRef = ref(useUser.carrierInfo?.fleetInfoVO?.name || ''); // 输入用
    const driverListRef = computed(() => carrierStore.drivers);
    const truckListRef = computed(() => carrierStore.trucks);
    const currentTabsRef = ref(carrierStore.currentTabs);
    const router = useRouter();
    const noContract = require('@/assets/htkb.png');
    const FormObj = {
      rules: [{ required: true, message: '车队名称不能为空！' }]
    };

    const handleChangeTab = e => {
      carrierStore.updateCurrentTabs(e);
      if (e === 'DRIVER') {
        carrierStore.getFleetDriversAction();
      } else {
        carrierStore.getFleetTrucksAction();
      }
    };

    const handleShowQRCode = () => {
      const { carrierInfoVO } = useUser.userInfo;
      //TODO: 调用司机原生
      ClientApp.showUserQRCode({
        identity: 'CARRIER',
        phone: carrierInfoVO.carrierPhone,
        userName: carrierInfoVO.carrierName,
        teamName: carrierInfoVO.fleetInfoVO.name,
        qrCode: `/driver/fleet/result?phone=${carrierInfoVO.carrierPhone}&identity=CARRIER`
      });
    };

    const truckVerifiedStore = useTruckVerifiedStore();

    const handleAddClick = async () => {
      if (currentTabsRef.value === 'DRIVER') {
        router.push(PageEnum.CARRIER_FLEET_INVITE_DRIVER);
      } else {
        // 添加车辆 有分层
        // 先清空缓存
        truckVerifiedStore.updateInfo(emptyForm());
        try {
          Toast.loading({
            duration: 0, // 持续展示 toast
            message: '加载中...'
          });
          const res = await getPlatformAppAuditModeApi();
          // 需要请求一个借口判断是什么认证类型
          truckVerifiedStore.updateAuthModeFields(authModeFactory.build(res.data));
          Toast.clear();
          router.push(PageEnum.BASE_CAR_ADD);
        } catch (error) {
          Toast.fail(error);
        }
        // router.push(PageEnum.BASE_CAR_ADD);
      }
    };

    const handleRouterBack = () => {
      router.push(PageEnum.BASE_MINE);
    };

    const handleEditCarrierName = async () => {
      if (carrierRef.value === '') {
        Toast.fail('车队名称不能为空!');
      } else {
        try {
          await apiModifyName({
            id: carrierName.value.id,
            name: carrierRef.value
          });
          Toast.success('修改成功!');
          await useUser.getUserInfoAction(); // 更新当前车队信息
        } catch (error) {
          console.error(error);
        } finally {
          visible.value = false;
        }
      }
    };

    onBeforeMount(async () => {
      try {
        await carrierStore.getFleetDriversAction();
      } finally {
        loading.value = false;
      }
    });

    return {
      loading,
      visible,
      FormObj,
      driverListRef,
      truckListRef,
      carrierRef,
      carrierName,
      currentTabsRef,
      handleChangeTab,
      noContract,
      handleRouterBack,
      handleShowQRCode,
      handleEditCarrierName,
      handleAddClick
    };
  }
});
</script>

<style lang="less" scoped>
.carrier-fleet-wrap {
  height: 100vh;
}
.fleet-name {
  background: #0076ff;
  width: 100vw;
  height: 0.96rem;
  .fleet-name-item {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 12px 0px rgba(199, 213, 229, 0.3);
    width: 100%;
    height: 100%;
  }
}
.page-content {
  top: 0.9rem !important;
}
.page-footer {
  .van-button {
    width: 3.1rem;
  }
}
.input-border {
  border-bottom: 1px solid rgba(188, 188, 188, 0.5);
  .van-cell {
    padding-left: 0;
    font-size: 16px;
  }
}
.carrier-form {
  width: 2.7rem;
  height: 2.04rem;
  border-radius: 4px;
  box-shadow: 0px 2px 20px 0px #ebeef2;
  .buttons {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0%;
    height: 0.48rem;
    .button-cancel {
      background: #f6f8fa;
      flex: 1;
    }
    .button-ok {
      background: #0076ff;
      color: white;
      flex: 1;
      box-shadow: 0px 1px 0px 0px rgba(188, 188, 188, 0.5) inset;
    }
  }
}
</style>
