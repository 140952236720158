<template>
  <van-cell class="flex align-center driver-item" @click="handleDriverClick">
    <div slot="icon" class="icon-wrap">
      <div class="flex align-center justify-center ">
        <Icon name="siji" class="font-20"> </Icon>
      </div>
    </div>
    <div>
      <div class="flex flex-row justify-space-between mb-4 ">
        <div>
          <span class="mr-8 text_main">{{ driver.name }}</span>
          <a @click.stop="" :href="`tel:${driver.phone}`"><Icon name="dh"/></a>
        </div>
        <div class="truck-no-wrap text_main">{{ driver.carLicensePlate }}</div>
      </div>
      <div class="flex flex-row justify-space-between">
        <div class="text_muted">{{ driver.phone }}</div>
        <div class="text_muted">
          {{ driver.truckTypeName }}
        </div>
      </div>
    </div>
  </van-cell>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { Cell } from 'vant';
import { useRouter } from '@/utils/compsitionHack';
import { PageEnum } from '@/enums/pageEnum';

export default defineComponent({
  name: 'driver-item',
  components: {
    [Cell.name]: Cell
  },
  props: {
    driver: {
      type: Object
    }
  },
  setup(props) {
    const router = useRouter();
    const id = props.driver.phone;
    const ids = props.driver.id;
    const handleDriverClick = () => {
      router.push({ path: PageEnum.CARRIER_FLEET_DRIVER, query: { id, ids } });
    };
    return {
      handleDriverClick
    };
  }
});
</script>

<style lang="less" scoped>
.driver-item {
  padding-bottom: 0;
  .icon-wrap {
    margin-right: 8px;
    margin-top: -0.16rem;
    & > div {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: rgba(135, 135, 135, 0.1);
    }
  }
  &::after {
    border-bottom: none;
  }
}
/deep/ .van-cell__value {
  padding-bottom: 0.16rem;
  &::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 0;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid #eeeeee;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}
</style>
